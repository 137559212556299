@import url('https://use.typekit.net/uir2eec.css');
@font-face {
  font-family: 'Schnyder';
  src: local('Schnyder'), url(./schnyder-s-normal-700.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sauce Sans';
  src: local('Open Sauce Sans'), url(./OpenSauceSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sauce Sans Light';
  src: local('Open Sauce Sans Light'), url(./OpenSauceSans-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sauce Sans Italic';
  src: local('Open Sauce Sans Italic'), url(./OpenSauceSans-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sauce Sans Medium';
  src: local('Open Sauce Sans Medium'), url(./OpenSauceSans-Medium.ttf) format('truetype');
}
body {
  background-color: black;
}
a {
  font-family: 'Open Sauce Sans Italic';
  color: #F4C1C1 !important;
}
.top:hover> .graffiti {
    opacity: 1;
    background-color: rgba(0,0,0,.6);
}
.graffiti {
  opacity: 0;
  transition: .33s all;
}
.link {
  font-family: 'Open Sauce Sans Italic';
  color: #F4C1C1 !important;
  
}
.link:hover {
  text-decoration: underline;
}
.txtb {
  font-family: 'Open Sauce Sans';
  line-height: 24px;
  
}
.stylizedTitle {
  font-family: 'Open Sauce Sans Medium';
}
.colorBlue {
  color: #92BABC !important;
}
.colorBlueBorder {
  border: 1px solid #92BABC !important;
}
.colorBlueBorder:hover {
  background-color: #92BABC !important;
  color: black !important;
}
.colorRedBorder:hover {
  background-color:#F4C1C1 !important;
  color: black !important;
}
.colorYellowBorder:hover {
  background-color: #FFEB90 !important;
  color: black !important;
}
.colorRed {
  color: #F4C1C1 !important;
}
.colorRedBorder {
  border: 1px solid #F4C1C1 !important;
}
.colorYellow {
  color: #FFEB90 !important;
}
.colorYellowBorder {
  border: 1px solid #FFEB90 !important;
}
.Show {
  position: relative;
}
.App {
  text-align: center;
  font-family: nimbus-sans, sans-serif;
  background-color: black;
  color: white;
  height: 100%;
}
.fieldLabel {
  font-size: 12px;
  font-weight: 500;
  opacity: .54;
  width: 128px;
  min-width: 128px;
}
.inputHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
}
button, input[type=submit] {
  padding: 20px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  transition: .3s all;
}
button:focus {
  outline: 0px solid transparent;
}
input[type=submit] {
  min-width: 360px;
}
button:hover {
  background-color: rgba(255,255,255,.2);
}
input {
  padding: 8px;
  
  background-color: transparent;
  border: 1px solid rgba(255,255,255,.33);
  outline: 0px solid transparent;
  color: white;

}
input::placeholder {
  color: rgba(255,255,255,.15);
}
textarea {
  padding: 8px;
  
  width: 480px;
  max-width: calc(100% - 16px);
  height: 84px;
  background-color: transparent;
  border: 1px solid rgba(255,255,255,.33);
  outline: 0px solid transparent;
  color: white;

}
textarea::placeholder {
  color: rgba(255,255,255,.15);
}

  select {
    color: white !important;
  }


.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
